// 1. 생략표시 없는 버튼
// import { Button } from '@mui/material';
// import React from 'react';
// import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
// import { RxDoubleArrowLeft, RxDoubleArrowRight } from 'react-icons/rx';

// function PageBtns({ length, nowIdx, onClick, handleFirstBtn, handlePrevBtn, handleLastBtn, handleNextBtn }) {
//   const arr = Array.from(Array(length), (_, index) => index + 1);

//   const buttonStyle = (isActive) => ({
//     backgroundColor: isActive ? "#104A62" : "white",
//     color: isActive ? "white" : "#104A62",
//     border: `1px solid #104A62`,
//     cursor: "pointer",
//     '&:hover': {
//       backgroundColor: isActive ? "#104A62" : "white",
//     },
//   });

//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "row",
//         gap: "10px",
//       }}
//     >
//       <Button 
//         onClick={handleFirstBtn}
//         size='small'
//         sx={{
//           backgroundColor: "transparent",
//           border: `1px solid #104A62`,
//           cursor: "pointer",
//           '&:hover': {
//             backgroundColor: "transparent",
//           },
//         }}
//         variant="outlined"
//       >
//         <RxDoubleArrowLeft color="#104A62" />
//       </Button>
//       <Button
//         onClick={handlePrevBtn}
//         size='small'
//         sx={{
//           backgroundColor: "transparent",
//           border: `1px solid #104A62`,
//           cursor: "pointer",
//           '&:hover': {
//             backgroundColor: "transparent",
//           },
//         }}
//         variant="outlined"
//       >
//         <IoIosArrowBack color="#104A62" />
//       </Button>

//       {arr && arr.map(i => (
//         <Button
//           key={i}
//           size='small'
//           sx={buttonStyle((nowIdx + 1) === i)}
//           onClick={() => onClick(i - 1)}
//           variant="contained"
//         >
//           {i}
//         </Button>
//       ))}
//       <Button
//         onClick={handleNextBtn}
//         size='small'
//         sx={{
//           backgroundColor: "transparent",
//           border: `1px solid #104A62`,
//           cursor: "pointer",
//           '&:hover': {
//             backgroundColor: "transparent",
//           },
//         }}
//         variant="outlined"
//       >
//         <IoIosArrowForward color="#104A62" />
//       </Button>
//       <Button
//         onClick={handleLastBtn}
//         size='small'
//         sx={{
//           backgroundColor: "transparent",
//           border: `1px solid #104A62`,
//           cursor: "pointer",
//           '&:hover': {
//             backgroundColor: "transparent",
//           },
//         }}
//         variant="outlined"
//       >
//         <RxDoubleArrowRight color="#104A62" />
//       </Button>
//     </div>
//   );
// }

// export default PageBtns;
//2. 생략표시 있는 버튼
import { Button } from "@mui/material";
import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";

function PageBtns({
  length,
  nowIdx,
  onClick,
  handleFirstBtn,
  handlePrevBtn,
  handleLastBtn,
  handleNextBtn,
}) {
  const buttonStyle = (isActive,isDot) => ({
    backgroundColor:isDot?"transparent": isActive ? "#104A62" : "white",
    color:isDot?"black": isActive ? "white" : "#104A62",
    border:isDot?null: "1px solid #104A62",
    cursor: isDot?"cursor":"pointer",
    "&:hover": {
      backgroundColor:isDot?"transparent":  isActive ? "#104A62" : "white",
    },
  });

  const getPageNumbers = () => {
    const pages = [];
    const start = Math.max(2, nowIdx - 1);
    const end = Math.min(length - 1, nowIdx + 3);

    // 항상 첫 페이지 추가
    pages.push(1);

    // 앞부분 "..." 추가 (현재 페이지가 4 이상이면)
    if (start > 2) {
      pages.push("...");
    }

    // 현재 페이지 기준 앞뒤 2개 페이지 추가
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    // 뒷부분 "..." 추가 (마지막 페이지 앞에 추가해야 하면)
    if (end < length - 1) {
      pages.push("...");
    }

    // 마지막 페이지 추가
    if (length > 1) {
      pages.push(length);
    }
const setPages=new Set(pages)
const pageList=Array.from(setPages)

    return pageList;
   
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
      <Button
        onClick={handleFirstBtn}
        size="small"
        sx={{
          backgroundColor: "transparent",
          border: "1px solid #104A62",
          cursor: "pointer",
          "&:hover": { backgroundColor: "transparent" },
        }}
        variant="outlined"
      >
        <RxDoubleArrowLeft color="#104A62" />
      </Button>
      <Button
        onClick={handlePrevBtn}
        size="small"
        sx={{
          backgroundColor: "transparent",
          border: "1px solid #104A62",
          cursor: "pointer",
          "&:hover": { backgroundColor: "transparent" },
        }}
        variant="outlined"
      >
        <IoIosArrowBack color="#104A62" />
      </Button>

      {getPageNumbers().map((item, idx) =>
        // item === "..." ? (
        //   <span key={idx} style={{ alignSelf: "center", color: "#104A62" }}>
        //     ...
        //   </span>
        // ) : (
          <Button
            key={item}
            size="small"
            sx={buttonStyle(nowIdx + 1 === item,item==="...")}
            onClick={() => {
              if (item==="...") 
                return;
                
              onClick(item - 1)}}
            variant={item==="..."?"text":"contained"}
          >
            {item}
          </Button>
        // )
      )}

      <Button
        onClick={handleNextBtn}
        size="small"
        sx={{
          backgroundColor: "transparent",
          border: "1px solid #104A62",
          cursor: "pointer",
          "&:hover": { backgroundColor: "transparent" },
        }}
        variant="outlined"
      >
        <IoIosArrowForward color="#104A62" />
      </Button>
      <Button
        onClick={handleLastBtn}
        size="small"
        sx={{
          backgroundColor: "transparent",
          border: "1px solid #104A62",
          cursor: "pointer",
          "&:hover": { backgroundColor: "transparent" },
        }}
        variant="outlined"
      >
        <RxDoubleArrowRight color="#104A62" />
      </Button>
    </div>
  );
}

export default PageBtns;
