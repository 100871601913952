import { collection, doc, getDocs, orderBy, query, setDoc, where, writeBatch } from 'firebase/firestore';
import { db } from './service/firebase';
import { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { useRecoilState } from 'recoil';
import { latestPots, deliveryPots, taxiPots, nowAreaState, siState, sidoState, loginDialogState } from './atom/atom';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import GoLoginDialog from './components/Dialog/GoLoginDialog';
import MainWriteCard from './components/Card/MainWriteCard';
import HorizontalDivider from './components/HorizontalDivider';
import PcResArea from './components/Bar/PcResArea';
import TopTenCard from './components/Card/TopTenCard';
import NotificationCard from './components/Card/NotificationCard';
import ChatCard from './components/Card/ChatCard';
import MbResArea from './components/Bar/MbResArea';
import AppstoreSrc from "./images/appstore.png"
import { findTopNUniversities, mdWidth, pcEmpty } from './const/const';
import { Mobile, PC } from './ReactiveLayout';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { IoMdMore } from 'react-icons/io';
import TempWriteCard from './components/Card/TempWriteCard';
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const { Kakao } = window;
function App() {
 
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const postsCol = collection(db, "posts");
  const [latestList, setLatestList] = useRecoilState(latestPots);
  const [deliveryList, setDeliveryList] = useRecoilState(deliveryPots);
  const [taxiList, setTaxiList] = useRecoilState(taxiPots);
  // const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
  // const [nowAddress, setNowAddress]=useState(params)
  const nowAddress=params
  const [siList, setSiList] = useRecoilState(siState);
  const [siDoList, setSiDoList] = useRecoilState(sidoState);
  const [sameMeetingPlaceList, setSameMeetingPlaceList] = useState([]);
  const [communityReplyList, setCommunityReplyList] = useState([]);
  const [reply, setReply] = useState("");
  const nowTime = new Date();
  const [allPosts, setAllPosts] = useState([]);
  const [newRegisterDate, setNewRegisterDate] = useState(nowTime);
  const [goLoginDialog, setGoLoginDialog] = useState(false);
  const [loginDialog, setLoginDialog] = useRecoilState(loginDialogState);
  const [notiPost, setNotiPost] = useState([]);
  const [nowSi,setNowSi]=useState(params.si)
  const jsKey='7c62fd16c038e08bbc3326ad42efb169'
  // 날짜순으로 글 가져오기
  const fetchPosts = async () => {
    try {
      const q = query(postsCol, orderBy("registerDate", "desc"));
      const querySnapshot = await getDocs(q);
      const posts = [];
      querySnapshot.forEach((doc) => {
        posts.push({docId:doc.id,...doc.data()});
      });
      return posts;
    } catch (error) {
      console.error("Error fetching posts: ", error);
      return [];
    }
  };

// useEffect(()=>{
//   setNowSi(params.si)
// },[params])
// useEffect(()=>{
//   const fetchEveryPosts = async () => {
//     if (!params.si ) return;
//     const allPosts = await fetchPosts();
    
//     setAllPosts(allPosts);
//     const matchedSiPlaceList = allPosts.filter(p => p.si === params.si && new Date(p.registerDate) >= new Date('2024-05-04'));
//     const matchedSiDoPlaceList = allPosts.filter(p => p.si === params.si && p.dong === params.dong && new Date(p.registerDate) >= new Date('2024-05-04'));
//     console.log("matchedSiPlaceList",matchedSiPlaceList)
//     setSiList(matchedSiPlaceList);
//     // setSiList([]);
//     // setSiDoList(matchedSiDoPlaceList.slice(0,5));
//   };

//   fetchEveryPosts();
//   // window.location.reload()
// },[nowSi])
  useEffect(() => {
 
console.log("params변경",nowAddress)
    const fetchEveryPosts = async () => {
      if (!nowAddress.si || !nowAddress.dong) return;
      const allPosts = await fetchPosts();
      setAllPosts(allPosts);
      const matchedSiPlaceList = allPosts.filter(p => p.si === params.si && new Date(p.registerDate) >= new Date('2024-05-04'));
      const matchedSiDoPlaceList = allPosts.filter(p => p.si === params.si && p.dong === params.dong && new Date(p.registerDate) >= new Date('2024-05-04'));
      console.log("allPosts",matchedSiPlaceList,nowAddress)
      setSiList(matchedSiPlaceList.slice(0,5));
      // setSiList([]);
      setSiDoList(matchedSiDoPlaceList.slice(0,5));
    };

    fetchEveryPosts();
  }, []);
console.log("시 최신글>>>",siList)
  const onChangeReply = (e) => {
    setReply(e.target.value);
  };

  const formatForDbRgDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${year}.${month}.${day} ${hours}:${minutes}`;
  };

  const goDeliveryMeetingPlace = () => {
    navigate(`/${nowAddress.fullAddress}/${nowAddress.si}/${nowAddress.dong}/delivery-table/meeting-place/1`);
  };

  const goDeliverySiPlace = () => {
    navigate(`/${nowAddress.fullAddress}/${nowAddress.si}/${nowAddress.dong}/delivery-table/si-place/1`);
  };

  const goDeliveryDongPlace = () => {
    navigate(`/${nowAddress.fullAddress}/${nowAddress.si}/${nowAddress.dong}/delivery-table/dong-place/1`);
  };

  const fetchCommunityPosts = async () => {
    try {
      const q = query(collection(db, "community"), orderBy("registerDate", "desc"));
      const querySnapshot = await getDocs(q);
      const posts = [];
      querySnapshot.forEach((doc) => {
        posts.push(doc.data());
      });
      return posts;
    } catch (error) {
      console.error("Error fetching posts: ", error);
      return [];
    }
  };

  const sortedDateList = (dataList) => {
    return dataList.slice().sort((a, b) => new Date(b.registerDate).getTime() - new Date(a.registerDate).getTime());
  };

  const fetchEveryCommunityPosts = async () => {
    const allCommunityPosts = await fetchCommunityPosts();
    const filtered = allCommunityPosts.filter(p => p.meetingPlace && p.meetingPlace === nowAddress.fullAddress);
    const sorted = sortedDateList(filtered);
    setCommunityReplyList(sorted);
  };

  useEffect(() => {
    fetchEveryCommunityPosts();
    fetchNoti();
  }, []);
  const deleteSpecificPosts = async () => {
    try {
      // 조건에 맞는 문서를 쿼리
      const q = query(postsCol, where("latitude", "==", 1), where("longitude", "==", 1));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        alert("삭제할 데이터가 없습니다.");
        return;
      }
  
      // writeBatch 사용
      const batch = writeBatch(db);
  
      querySnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
  
      await batch.commit(); // 배치 연산 실행
      alert("위도 1, 경도 1인 데이터를 성공적으로 삭제했습니다!");
    } catch (error) {
      console.error("데이터 삭제 중 오류 발생: ", error);
      alert("데이터 삭제 중 문제가 발생했습니다.");
    }
  };
  
  const onEnterReply = async (e) => {
    const docId = uuid();
    if (e.key === 'Enter') {
      const login = sessionStorage.getItem("login");
      if (login !== "success") {
        setGoLoginDialog(true);
      } else {
        await setDoc(doc(db, "community", docId), {
          ownerUserId: "temp",
          registerDate: formatForDbRgDate(newRegisterDate),
          reply: reply,
          meetingPlace: nowAddress.fullAddress,
          si: nowAddress.si,
          dong: nowAddress.dong,
        });
        fetchEveryCommunityPosts();
        setReply("");
      }
    }
  };

  const notiCol = collection(db, "notification");
  const top10Univs = findTopNUniversities(allPosts.filter(p => p.si !== undefined || p.dong !== undefined), 10);

  const getNotiPosts = async () => {
    try {
      const querySnapshot = await getDocs(notiCol);
      const posts = [];
      querySnapshot.forEach((doc) => {
        posts.push(doc.data());
      });
      return posts;
    } catch (error) {
      console.error("Error fetching posts: ", error);
      return [];
    }
  };
  // useEffect(() => {
  //   if (!window.Kakao.isInitialized()) {
  //     window.Kakao.init(jsKey); // JavaScript 키를 여기에 입력
  //     console.log('Kakao SDK Initialized');
  //   }
  // }, []);
  const fetchNoti = async () => {
    const posts = await getNotiPosts();
    setNotiPost(posts);

  };
  useEffect(()=>{ 
   
    	// init 해주기 전에 clean up 을 해준다.
        Kakao.cleanup();
        // 자신의 js 키를 넣어준다.
        Kakao.init(jsKey);
        // 잘 적용되면 true 를 뱉는다.
       
    },[]);
  
  // const shareKakao = async () => {
  //   if(!Kakao.isInitialized()){
  //     Kakao.init(jsKey);
  //   }
  
  //   try {
  //         // 기타 네트워크 요청 코드 생략
        
  //     const url = "https://itshonigo.com";
    
  //     Kakao.Share.sendDefault({
  //       objectType: "feed",
  //       content: {
  //         title: "같이시켜",
  //         description: '같이시켜',
  //         imageUrl: '이미지url',
  //         link: {
  //           mobileWebUrl: url,
  //           webUrl: url,
  //         },
  //       },
  //       buttons: [
  //         {
  //           title: "결과 보기",
  //           link: {
  //             mobileWebUrl: url,
  //             webUrl: url,
  //           },
  //         },
  //         {
  //           title: "나도 질문하기",
  //           link: {
  //             mobileWebUrl: "https://gptarot.jiwoo.best",
  //             webUrl: "https://gptarot.jiwoo.best",
  //           },
  //         },
  //       ],
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  const shareKakao=()=>{
    Kakao.Share.sendDefault({
            objectType: 'feed',
            content: {
                title: '같이시켜',
                description: '같이시켜',
                
                imageUrl:"",
                link: {
                    mobileWebUrl: "https://itshonigo.com"//window.location.href,
                },
            },
            buttons: [
                {
                    title: '같이시켜',
                    link: {
                    mobileWebUrl: "https://itshonigo.com"//window.location.href,
                    },
                },
                ],
            });
    

}

  console.log("siList.slice(0, 5)",siList.slice(0, 5))
  return (
    <div
    style={{
        display: 'flex',
        flexDirection: "column",
        alignItems: "center"
      }}
    
    >
      {/* <button onClick={deleteSpecificPosts}>위도1경도1 인 데이터 삭제하는 버튼</button> */}
      <Mobile>
        <GoLoginDialog goLoginDialog={goLoginDialog} setGoLoginDialog={setGoLoginDialog} setLoginDialog={setLoginDialog} />
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", minWidth: "300px" }}>
          <MbResArea title={nowAddress.fullAddress} nowAddress={nowAddress} goDelivery={goDeliveryMeetingPlace} />
          <HorizontalDivider width={"100%"} />
          <MainWriteCard placeType={"si"} title={nowAddress.si ? `${nowAddress.si} 최신글` : "주소를 선택해 주세요"} writes={siList.slice(0, 5)} onClick={goDeliverySiPlace} />
          <MainWriteCard placeType={"dong"} title={nowAddress.si ? `${nowAddress.si} ${nowAddress.dong} 최신글` : "주소를 선택해 주세요"} writes={siDoList.slice(0, 5)} onClick={goDeliveryDongPlace} />
        
        </div>
      </Mobile>
      <PC>
     {/* 애드센스 심사 통과를 위한 임시 컴포넌트 */}
     <div style={{ height: pcEmpty }} />
     
        <GoLoginDialog goLoginDialog={goLoginDialog} setGoLoginDialog={setGoLoginDialog} setLoginDialog={setLoginDialog} />
        <div style={{ height: pcEmpty }} />
        <PcResArea title={nowAddress.fullAddress} nowAddress={nowAddress} goDelivery={goDeliveryMeetingPlace} />
        <HorizontalDivider width={mdWidth} />
        <div style={{ height: pcEmpty }} />
        <div style={{ width: "calc(100% - 80px - 80px)", display: "flex", flexDirection: "row", gap: "8px" }}>
          <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "16px" }}>
            <TempWriteCard placeType={"si"} title={nowAddress.si ? `${nowAddress.si} 최신글` : "주소를 선택해 주세요"} writes={siList} onClick={goDeliverySiPlace} />
            <MainWriteCard placeType={"si"} title={nowAddress.si ? `${nowAddress.si} 최신글` : "주소를 선택해 주세요"} writes={siList} onClick={goDeliverySiPlace} />
            <MainWriteCard placeType={"dong"} title={nowAddress.si ? `${nowAddress.si} ${nowAddress.dong} 최신글` : "주소를 선택해 주세요"} writes={siDoList} onClick={goDeliveryDongPlace} />
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          
            <TopTenCard list={top10Univs} />
            <NotificationCard list={notiPost.slice(0, 3)} />
            <ChatCard reply={reply} replys={communityReplyList} onChangeReply={onChangeReply} onEnterReply={onEnterReply} />
          </div>
        </div>
      </PC>
    </div>
  );
}

export default App;
